// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/login_bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-wrap[data-v-98705b8e]{width:100%;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;display:flex;justify-content:center;align-items:center;flex-direction:column}.ms-title[data-v-98705b8e]{width:100%;line-height:50px;text-align:center;font-size:20px;color:#212121;border-bottom:1px solid #ddd}.ms-login[data-v-98705b8e]{width:350px;border-radius:5px;background:hsla(0,0%,100%,.5);box-shadow:4px 6px 8px #212121}.ms-content[data-v-98705b8e]{padding:30px 30px}.login-btn[data-v-98705b8e]{text-align:center}.login-btn button[data-v-98705b8e]{width:100%;height:36px;margin-bottom:10px}.login-tips[data-v-98705b8e]{font-size:12px;line-height:30px;color:#000}.row[data-v-98705b8e]{display:flex;flex-direction:row;justify-content:space-between}.company[data-v-98705b8e]{display:flex;justify-content:center;align-items:center;color:#212121;font-weight:700;font-size:24px;margin-bottom:20px}.forget[data-v-98705b8e],.reg[data-v-98705b8e]{font-size:14px;color:orange}.triangle[data-v-98705b8e]{display:flex;justify-content:center;width:100%;font-size:20px;color:hsla(0,0%,100%,.7);top:-20px;position:relative;height:1px}", ""]);
// Exports
module.exports = exports;
