<template>
  <div class="login-wrap">
    <div class="company">
      <el-image
          style="width: 50px; height: 50px; margin-right: 10px"
          :src="require('../assets/images/logo.png')"
          fit="fit"
      ></el-image>
      枫叶出行
    </div>
    <div class="ms-login">
      <div class="triangle"><span>▲️</span></div>
      <div class="ms-title">监控系统</div>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="0px"
          class="ms-content"
      >
        <el-form-item prop="username">
          <el-input v-model="ruleForm.username" placeholder="username">
            <el-button slot="prepend" icon="el-icon-user"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              type="password"
              placeholder="password"
              v-model="ruleForm.password"
              @keyup.enter="submitForm('ruleForm')"
          >
            <el-button slot="prepend" icon="el-icon-lock"></el-button>
          </el-input>
        </el-form-item>

        <div class="login-btn">
          <el-button
              type="primary"
              :loading="inLoading"
              @click="submitForm('ruleForm')"
          >登录
          </el-button
          >
          <div class="row"></div>
        </div>
        <p class="login-tips">v1.0.3</p>
      </el-form>
    </div>
  </div>
</template>

<script>
import {Message} from "element-ui";
import {requestLogin} from "../api/system/user";
//import md5 from 'js-md5'
export default {
  data: function () {
    return {
      logoImg: "../assets/images/logo.jpg",
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {required: true, message: "请输入用户名", trigger: "blur"},
        ],
        password: [{required: true, message: "请输入密码", trigger: "blur"}],
      },
      inLoading: false,
    };
  },
  mounted() {
    this.autoLogin()
  },
  methods: {
    autoLogin() {
      let token = this.$route.query.fytoken
      if (token) {
        this.inLoading = true;
        this.$store.dispatch("autoLogin", {
          token: token
        }).then((res) => {
          if (res.code == 0) {
            this.$router.push("/");
          }
        }).catch(() => {
          this.inLoading = false;
        });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.inLoading = true;
          this.$store
              .dispatch("login", {
                name: this.ruleForm.username,
                pwd: this.ruleForm.password,
              })
              .then((res) => {
                this.inLoading = false;
                if (res.code == 0) {
                  this.$router.push("/");
                }
              })
              .catch(() => {
                this.inLoading = false;
              });
        } else {
          return false;
        }
      });
    },
    loginSystem() {
      let self = this;
      // 改成明文验证 let params = {name: this.ruleForm.username, pwd: md5(this.ruleForm.password)}
      let params = {
        name: this.ruleForm.username,
        pwd: this.ruleForm.password,
      };
      requestLogin(params)
          .then((response) => {
            if (response.code == 0) {
              let token = response.result.token;
              localStorage.setItem("token", token);
              self.$router.push("/");
            } else {
              Message.error({
                message: "登录失败 [" + response.msg + "]",
              });
            }
            self.inLoading = false;
          })
          .catch((err) => {
            console.log(err);
            self.inLoading = false;
          });
    },
  },
};
</script>

<style scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  background: url(../assets/images/login_bg.jpg) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #212121;
  border-bottom: 1px solid #ddd;
}

.ms-login {
  width: 350px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 6px 8px #212121;
}

.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}

.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #000;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.company {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212121;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}

.reg {
  font-size: 14px;
  color: #ffa500;
}

.forget {
  font-size: 14px;
  color: #ffa500;
}

.triangle {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
  top: -20px;
  position: relative;
  height: 1px;
}
</style>
